import { defineAsyncComponent, Plugin } from 'vue';
import PortalVue from 'portal-vue';
import IdeoPlugin from '@/components/ideo';
import DynamicFormsPlugin from '@/components/forms';

import ActionBar from '@/components/action/ActionBar.vue';
import ActionButton from '@/components/action/ActionButton.vue';
import ActionButtonAi from '@/components/action/ActionButtonAi.vue';
import ActionHeader from '@/components/action/ActionHeader.vue';
import ActionIcon from '@/components/action/ActionIcon.vue';
import ListView from '@/components/list/ListView.vue';
import ListViewSettings from '@/components/list/ListViewSettings.vue';
import ListViewHeader from '@/components/list/properties/ListViewHeader.vue';
import ListViewImage from '@/components/list/properties/ListViewImage.vue';
import ListViewLabel from '@/components/list/properties/ListViewLabel.vue';
import ListViewItem from '@/components/list/properties/ListViewItem.vue';
import ListViewButtons from '@/components/list/properties/ListViewButtons.vue';
import ListViewButton from '@/components/list/properties/ListViewButton.vue';
import ListViewCheckbox from '@/components/list/properties/ListViewCheckbox.vue';
import ListViewHierarchy from '@/components/list/properties/ListViewHierarchy.vue';
import ListViewHandle from '@/components/list/properties/ListViewHandle.vue';
import Confirmation from '@/components/common/Confirmation.vue';

const ComponentsPlugin: Plugin =
{
    async install(app, options)
    {
        // Ideo Bootstrap Components
        app.use(IdeoPlugin);

        // Custom components
        app.component('ActionBar', ActionBar);
        app.component('ActionButton', ActionButton);
        app.component('ActionButtonAi', ActionButtonAi);
        app.component('ActionHeader', ActionHeader);
        app.component('ActionIcon', ActionIcon);
        app.component('ListView', ListView);
        app.component('ListViewSettings', ListViewSettings);
        app.component('ListViewHeader', ListViewHeader);
        app.component('ListViewImage', ListViewImage);
        app.component('ListViewLabel', ListViewLabel);
        app.component('ListViewItem', ListViewItem);
        app.component('ListViewButtons', ListViewButtons);
        app.component('ListViewButton', ListViewButton);
        app.component('ListViewCheckbox', ListViewCheckbox);
        app.component('ListViewHierarchy', ListViewHierarchy);
        app.component('ListViewHandle', ListViewHandle);
        app.component('Confirmation', Confirmation);

        // Custom async components
        app.component('Editor', defineAsyncComponent(() => import('@/components/common/Editor.vue')));
        app.component('Filepicker', defineAsyncComponent(() => import('@/components/common/FilePicker.vue')));
        app.component('Fileupload', defineAsyncComponent(() => import('@/components/common/FileUpload.vue')));
        app.component('Pagination', defineAsyncComponent(() => import('@/components/common/Pagination.vue')));
        app.component('PaginationInfo', defineAsyncComponent(() => import('@/components/common/PaginationInfo.vue')));
        app.component('PaginationBar', defineAsyncComponent(() => import('@/components/common/PaginationBar.vue')));
        app.component('Responsive', defineAsyncComponent(() => import('@/components/common/Responsive.vue')));
        app.component('Sort', defineAsyncComponent(() => import('@/components/common/Sort.vue')));
        app.component('DataCard', defineAsyncComponent(() => import('@/components/data/DataCard.vue')));
        app.component('DataSize', defineAsyncComponent(() => import('@/components/data/DataSize.vue')));
        app.component('DataSorting', defineAsyncComponent(() => import('@/components/data/DataSorting.vue')));
        app.component('DataExporting', defineAsyncComponent(() => import('@/components/data/DataExporting.vue')));
        app.component('Placeholder', defineAsyncComponent(() => import('@/components/placeholders/Placeholder.vue')));
        app.component('PlaceholderForm', defineAsyncComponent(() => import('@/components/placeholders/Form.vue')));
        app.component('PlaceholderGrid', defineAsyncComponent(() => import('@/components/placeholders/Grid.vue')));
        app.component('Filemanager', defineAsyncComponent(() => import('@/components/filemanager/FileManager.vue')));

        // Filters
        app.component('IdeoInlineFilters', defineAsyncComponent(() => import('@/components/filters/IdeoInlineFilters.vue')));
        app.component('IdeoFilter', defineAsyncComponent(() => import('@/components/filters/IdeoFilter.vue')));
        app.component('IdeoFilterText', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterText.vue')));
        app.component('IdeoFilterSelect', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterSelect.vue')));
        app.component('IdeoFilterTreeSelect', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterTreeSelect.vue')));
        app.component('IdeoFilterMultiple', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterMultiple.vue')));
        app.component('IdeoFilterDatetime', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterDatetime.vue')));
        app.component('IdeoFilterCheckbox', defineAsyncComponent(() => import('@/components/filters/types/IdeoFilterCheckbox.vue')));

        // Formularze dynamiczne
        app.use(DynamicFormsPlugin);

        // External components
        app.use(PortalVue);
    }
};

export default ComponentsPlugin;
