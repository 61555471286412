import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { entry, Entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'color-picker',
    name: '[[[Selektor kolorów]]]',
    icon: 'fa-palette',
    group: 'special',
    position: 6
};

export class ColorPickerEntry extends ValidEntry<string>
{
    public type: string = Definition.type;
    public data: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: ColorPickerContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        const readonly = form.expressions.readonly(blueprint);
        const data = this.data == null || (readonly && blueprint.defaultValue) ? (form.expressions.executeExpression(blueprint.defaultValue) || '') : this.data;

        return entry({
            type: this.type,
            data
        });
    }

    public validate(blueprint: ColorPickerContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const readonly = form.expressions.readonly(blueprint);
        const data = this.data == null || (readonly && blueprint.defaultValue) ? (form.expressions.executeExpression(blueprint.defaultValue) || '') : this.data;

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            const required = form.expressions.required(blueprint);
            const empty = data.length == 0;

            if (required && empty)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instanceOfColorPickerEntry = (object: any): object is ColorPickerEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface ColorPickerContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    defaultValue: string;
}

export class ColorPickerType implements ColorPickerContract, ValidatableBlueprint, EntryFactory<ColorPickerEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public defaultValue: string;


    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Selektor koloru' };
        this.help = {};
        this.showLabel = true;
        this.minWidth = 1;
        this.width = 0;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.defaultValue = '';
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): ColorPickerEntry
    {
        return new ColorPickerEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
