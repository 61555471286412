<script setup lang="ts">
import { useAttrs, computed } from 'vue';
import InputNumber from 'primevue/inputnumber'; // https://primevue.org/inputnumber/
import { useLocalization } from '@/plugins/localization';

defineOptions({
    name: 'ideo-form-number',
    components: {
        'p-input-number': InputNumber
    },
    inheritAttrs: false,
});

const { $i18n } = useLocalization();

const model = defineModel<number>();

const props = withDefaults(defineProps<{
    locale?: string;
    fluid?: boolean;
    clearable?: boolean,
    min?: number,
    // ... additional props: https://primevue.org/inputnumber/#api.inputnumber.props
}>(), {
    locale: null,
    fluid: true,
    clearable: false,
    min: 0,
});

const emit = defineEmits<{
    (e: 'input', value: number): void;
}>();

const attrs = useAttrs();

const locale = computed(() => props.locale ?? $i18n.locale());
const classes = computed(() => ({
    'wrapper': true,
    'clearable': props.clearable
}));

const resetValue = (): void =>
{
    model.value = 0;
};

const handleInput = (payload: { value: number, formattedValue: string, originialEvent: KeyboardEvent }): void =>
{
    emit('input', payload.value);
};

const handleUpdate = (value: number | null) =>
{
    if (value === null)
    {
        model.value = props.min;
        emit('input', props.min);
    }
};
</script>

<template>
    <div :class="classes">
        <p-input-number
            v-model="model"
            :locale="locale"
            :fluid="fluid"
            v-bind="attrs"
            @input="handleInput"
            @update:modelValue="handleUpdate"
        />

        <button v-if="clearable" @click.prevent="resetValue" class="clear-btn btn btn-secondary">
            <i class="fas fa-fw fa-times"></i>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    margin-top: -1px;
}

:deep(input) {
    width: 100%;
}

.clearable {
    &:deep(input) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    .clear-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>
