import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes';

export default definePreset(Lara, {
    semantic: {
        primary: {
            50: '{orange.50}',
            100: '{orange.50}',
            200: '{orange.100}',
            300: '{orange.200}',
            400: '{orange.300}',
            500: '{orange.400}',
            600: '{orange.500}',
            700: '{orange.600}',
            800: '{orange.700}',
            900: '{orange.800}',
            950: '{orange.900}'
        },
        formField: {
            paddingX: 12,
            paddingY: 6,
            borderRadius: 4,
            focusRing: {
                width: '0',
                style: 'none',
                color: 'transparent',
                offset: '0',
                shadow: 'none'
            }
        }
    },
    components: {
        checkbox: {
            colorScheme: {
                light: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                },
                dark: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                }
            }
        },
        radiobutton: {
            colorScheme: {
                light: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                },
                dark: {
                    root: {
                        width: 16,
                        height: 16
                    },
                    icon: {
                        size: 10
                    }
                }
            }
        },
        toggleswitch: {
            colorScheme: {
                light: {
                    root: {
                        width: 24,
                        height: 16
                    },
                    handle: {
                        size: 10
                    }
                },
                dark: {
                    root: {
                        width: 24,
                        height: 16
                    },
                    handle: {
                        size: 10
                    }
                }
            }
        },
        datatable: {
            colorScheme: {
                light: {
                    headerCell: {
                        padding: '6px 12px',
                    },
                    bodyCell: {
                        padding: '4px 12px',
                    },
                    columnTitle: {
                        fontWeight: 600
                    }
                },
                dark: {}
            }
        },
        card: {
            colorScheme: {
                light: {
                    root: {
                        background: '{surface.0}',
                        color: '{red.700}',
                        borderRadius: 'none'
                    },
                    subtitle: {
                        color: '{surface.500}'
                    }
                },
                dark: {
                    root: {
                        background: '{surface.900}',
                        color: '{surface.0}'
                    },
                    subtitle: {
                        color: '{surface.400}'
                    }
                }
            }
        },
        treeselect: {
            colorScheme: {
                light: {
                    background: 'var(--bs-tertiary-bg)',
                    padding: {
                        x: '0.75rem',
                        y: '0.375rem'
                    },
                    transition: {
                        duration: 0
                    },
                    border: {
                        color: 'var(--bs-border-color)',
                        radius: 'var(--bs-border-radius)',
                    },
                    dropdown: {
                        width: '32px',
                    },
                    hover: {
                        border: {
                            color: 'var(--bs-border-color)',
                        }
                    },
                    focus: {
                        border: {
                            color: 'var(--bs-border-color)',
                        }
                    },
                    invalid: {
                        border: {
                            color: 'var(--bs-danger)'
                        }
                    },
                    overlay: {
                        background: 'var(--bs-tertiary-bg)',
                        shadow: 'var(--bs-box-shadow)',
                        border: {
                            color: 'var(--bs-border-color)',
                            radius: 'var(--vs-border-radius)',
                        }
                    },
                    tree: {
                        padding: '4px'
                    }
                },
                dark: {}
            }
        },
        select: {
            background: "var(--bs-tertiary-bg)",
            transition: {
                duration: 'none'
            },
            focus: {
                border: {
                    color: "var(--bs-primary-border-subtle)"
                }
            },
            overlay: {
                background: "var(--bs-tertiary-bg)",
                shadow: "var(--bs-box-shadow)",
                border: {
                    color: "var(--bs-primary-border-subtle)",
                    radius: "var(--bs-border-radius)"
                }
            },
            option: {
                padding: 0,
                group: {
                    padding: '0'
                },
                focus: {
                    background: 'var(--bs-primary-bg-subtle)'
                },
                selected: {
                    background: 'var(--ideo-select-option-checked-bg)',
                    color: "var(--ideo-select-option-checked-color)",

                    focus: {
                        background: 'var(--ideo-select-option-checked-bg)',
                        color: "var(--ideo-select-option-checked-color)",
                    }
                },
            },
            optionGroup: {
                padding: 0,
                color: 'var(--bs-body-color)'
            },
            dropdown: {
                width: '2rem',
                color: 'var(--bs-body-color)'
            }
        },
        listbox: {
            background: "var(--bs-tertiary-bg)",
            transition: {
                duration: 0
            },
            focus: {
                border: {
                    color: "var(--bs-primary-border-subtle)"
                }
            },
            option: {
                padding: '5px 10px',
                focus: {
                    background: 'var(--bs-primary-bg-subtle)'
                },
                selected: {
                    background: 'var(--ideo-select-option-checked-bg)',
                    color: "var(--ideo-select-option-checked-color)",

                    focus: {
                        background: 'var(--ideo-select-option-checked-bg)',
                        color: "var(--ideo-select-option-checked-color)",
                    }
                }
            }
        }
    }
});
