<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { normalizeClasses } from '@/helpers/Utils';

defineOptions({
    name: 'ideo-form-group'
});

const props = withDefaults(defineProps<{
    label?: string;
    tag?: string;
    labelFor?: string;
    invalidFeedback?: string;
    state?: boolean;
    required?: boolean;
    nospace?: boolean;
    labelClass?: Record<string, boolean> | string[] | string;
}>(), {
    label: null,
    tag: 'span',
    labelFor: null,
    invalidFeedback: null,
    state: null,
    labelClass: () => ({})
});

const loaded = ref(false);
const helpicon = ref(null);

const invalid = computed(() => props.state === false || (props.state === null && !!props.invalidFeedback));

const labelClasses = computed(() => ({
    'w-100 d-flex align-items-center gap-2': true,
    'text-danger': invalid.value,
    ...normalizeClasses(props.labelClass)
}));

const flag = (value: any): boolean =>
{
    return value !== false;
};

const onClick = (e: any): void =>
{
    if (!props.labelFor)
        e.stopPropagation();
};

onMounted(() =>
{
    loaded.value = true;
});
</script>

+<template>
    <div class="form-group" :class="{'mb-0': flag(nospace), 'invalid': invalid}">
        <label :for="labelFor" :class="labelClasses" v-if="label" @click="onClick">
            <component :is="tag">
                {{ label }}

                <i class="far fa-fw fa-circle-question" ref="helpicon" v-if="$slots.help"></i>
            </component>
            <i class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="flag(required) && !invalid"></i>
            <i class="fa-solid fa-fw fa-triangle-exclamation" :title="invalidFeedback" v-if="invalid"></i>
        </label>
        <slot name="default"></slot>
        <template v-if="$slots.description">
            <small class="form-text text-muted"><slot name="description"></slot></small><br>
        </template>
        <small class="form-text text-danger" v-if="invalid">{{ invalidFeedback }}</small>
        <template v-if="loaded && $slots.help">
            <ideo-popover class="popover" :target="helpicon" placement="right" triggers="hover">
                <slot name="help"></slot>
            </ideo-popover>
        </template>
    </div>
</template>
