<script lang="ts" setup>
import { ref, inject, Ref, getCurrentInstance, watch } from 'vue';
import { AccordionProps } from './types';

const props = withDefaults(defineProps<{
    header?: string,
    icon?: string
}>(), {
    header: null,
    icon: null
});
const emit = defineEmits<{
    (e: 'show'): void,
    (e: 'hide'): void,
}>();

const instance = getCurrentInstance();

const show = ref(false);
const accordion = inject<AccordionProps>('accordion');
const opened = inject<Ref<string[]>>('opened');

function onClick()
{
    if (accordion.alwaysOpen == false)
        opened.value = [];

    show.value = !show.value;

    if (show.value)
        opened.value.push(instance.uid.toString());
    else
        opened.value = opened.value.filter(p => p != instance.uid.toString());
}

watch(show, (value) =>
{
    value ? emit('show') : emit('hide');
});

watch(opened, (value) =>
{
    if (!value.includes(instance.uid.toString()))
    {
        show.value = false;
    }
});
</script>

<template>
    <div :class="[accordion.itemClass, (show ? accordion.itemClassActive : {}), (accordion.border ? 'accordion-item' : '')]">
        <component :is="accordion.headerTag" class="accordion-header" @click="onClick">
            <button type="button" class="accordion-button" :class="[accordion.headerClass, (show ? accordion.headerClassActive : {}), {'collapsed': !show}]">
                <slot name="icon">
                    <i class="far fa-fw me-3" :class="props.icon" v-if="props.icon"></i>
                </slot>
                <span class="w-100" :class="accordion.labelClass">
                    <slot name="header">
                        {{ props.header }}
                    </slot>
                </span>
                <i class="far fa-fw fa-chevron-down" v-if="accordion.icon"></i>
            </button>
        </component>
        <div class="accordion-collapse collapse" :class="{'show': show}">
            <div class="accordion-body" :class="accordion.contentClass">
                <slot name="default"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.accordion-button::after {
    display: none;
}
.accordion-button .fa-chevron-down {
    transition: transform 0.3s;
    transform: rotate(180deg);
}
.accordion-button.collapsed .fa-chevron-down {
    transition: transform 0.3s;
    transform: rotate(0deg);
}
</style>
