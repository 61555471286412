<script setup lang="ts">
defineOptions({
    name: 'ideo-form-select'
});

const model = defineModel<any>();
const props = withDefaults(defineProps<{
    valueField?: string;
    textField?: string;
    disabledField?: string;
    options?: any[],
    placeholder?: string;
}>(), {
    options: (): any[] => [],
    valueField: 'value',
    textField: 'text',
    disabledField: 'disabled',
    placeholder: undefined
});

const optionValue = (option: Record<string, any>) => option[props.valueField];
const optionText = (option: Record<string, any>) => option[props.textField];
const optionDisabled = (option: Record<string, any>) => option[props.disabledField] ?? false;
</script>

<template>
    <select class="form-select" v-model="model">
        <slot name="first">
            <ideo-form-select-option :value="null" v-if="props.placeholder">
                {{ props.placeholder }}
            </ideo-form-select-option>
        </slot>
        <slot name="default">
            <ideo-form-select-option v-for="(option, i) in options" :value="optionValue(option)" :key="i" :disabled="optionDisabled(option)">
                {{ optionText(option) }}
            </ideo-form-select-option>
        </slot>
    </select>
</template>
