<script setup lang="ts">
import { ref, computed, useAttrs, defineModel, watch } from 'vue';
import TreeSelect from 'primevue/treeselect';

defineOptions({
    name: 'ideo-form-tree-select',
    components: {
        'tree-select': TreeSelect
    }
});

const model = defineModel<number>({ default: null });

const props = withDefaults(defineProps<{
    fluid?: boolean,
    transform?: boolean,
    options?: any[],
    emptyOption?: string
}>(), {
    fluid: true,
    options: () => ([]),
    emptyOption: ''
});

const attrs = useAttrs();

const selected = ref();

watch(model, (value) =>
{
    selected.value = isNaN(value) ? {null: true} : {[value]: true};
}, { immediate: true });

const handleChange = (value: any): void =>
{
    const key = Object.keys(value)[0];

    model.value = key === 'null' ? null : parseInt(key);
};

const transformData = (): any[] =>
{
    const nodeMap: Record<string | number, any> = {};
    const result: any[] = [];

    // 1. Stworzenie węzłów oraz folderów
    props.options.forEach((item) =>
    {
        if (item.id === 0)
        {
            // Element jest folderem - nie posiada ID
            const folderId = `folder-${item.folderId}`;
            const folder = {
                key: folderId,
                label: item.name.trim(),
                selectable: false,
                children: [] as any
            };

            result.push(folder);
            nodeMap[folderId] = folder;
        }
        else
        {
            // Element jest węzłem
            nodeMap[item.id] = {
                key: item.id,
                label: item.name.trim(),
                leaf: !item.hasChildren,
                children: [] as any
            };
        }
    });

    // 2. Przypisanie potomków do rodziców
    props.options.forEach((item) =>
    {
        if (item.id !== 0)
        {
            const node = nodeMap[item.id];

            if (item.parentId === null)
            {
                // Węzeł bez parentId -> sprawdzamy czy folder istnieje
                const folderKey = `folder-${item.folderId}`;

                if (nodeMap[folderKey])
                {
                    nodeMap[folderKey].children.push(node);
                }
                else
                {
                    // Brak folderu -> węzeł staje się elementem głównym
                    result.push(node);
                }
            }
            else
            {
                // Węzeł ma parentId -> dodaj do rodzica
                const parent = nodeMap[item.parentId];

                if (parent)
                {
                    parent.children.push(node);
                }
            }
        }
    });

    return result;
};


const options = computed(() =>
{
    const items = props.transform ? transformData() : props.options;

    if (props.emptyOption)
        return [{ key: null, label: props.emptyOption }, ...items];

    return items;
});
</script>

<template>
    <tree-select
        :fluid="fluid"
        v-model="selected"
        @change="handleChange"
        v-bind="attrs"
        :options="options"
    />
</template>

<style lang="scss">
.p-tree-root-children {
    margin-bottom: 0;
    padding-left: 0;
}

.p-treeselect-overlay {
    z-index: 10314 !important;
}
</style>
